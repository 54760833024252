/**
Author - Brijesh Pandey
Git - https://github.com/Brijesh-Pandey
**/

import Cookie, { adjustToStartOfWeekAndMonth } from "./helpers";

function checkEnvironment() {
  const origin = window.location.origin;

  if (origin === 'https://charts.wealthy.in') {
    return true;
  } else {
    return false;
  }
}

const isProd = checkEnvironment();



const baseURL = isProd ? "https://api.wealthy.in/broking" : "https://api.wealthydev.in/broking";
const hasAuthCookie = Cookie.getCookie("wl_authorization");

const prodToken = hasAuthCookie ? hasAuthCookie : "7dfaf86c-5bf1-45a4-8777-2103a1dfca61:KhDWgG2Kg2t1hb1m4VfySMjBa";
const devToken = hasAuthCookie ? hasAuthCookie : "30ed49c9-a0de-4d16-860e-5591f86d4e53:ANvKLqnKH76IMclEWStPInkUa";

let data = null;

let receivedData = null;
const channelToSubscription = new Map();


const secondsMap = {
  "1": 60,
  "3": 180,
  "5": 300,
  "15": 900,
  "30": 1800,
  "60": 3600,
  "240": 4 * 3600,
  "1D": 24 * 3600,
  "1W": 7 * 24 * 3600,
  "1M": 30 * 24 * 3600
};


const getData = async (symbol, subscriberUID, resolution, lastDailyBar) => {
  let prevEpoch = lastDailyBar?.time || 0;

  console.log(lastDailyBar, "before everything")

  let initBar = {
    time: lastDailyBar?.time,
    open: Number.NEGATIVE_INFINITY,
    high: Number.NEGATIVE_INFINITY,
    low: Number.POSITIVE_INFINITY,
    // close: lastDailyBar?.close,
    // volume: 0
  };

  let prevBar = {...lastDailyBar};

  try {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", isProd ? prodToken : devToken);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("X-APP-VERSION", "broking-web");

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };


    const res = await fetch(`${baseURL}/api/v0/auth/ws-url/?source=WEB`, requestOptions)
    data = await res.json();

    const {
      name,
      ticker,
      exchange
    } = symbol

    if (data) {
      const socketBaseUrl = isProd ? "wss://trade.wealthy.in" : "ws://noren.wealthydev.in"
      const connectionString = `${socketBaseUrl}${data.base_url}`;
      const authorizationBody = {...data.body}

      const webSocket = new WebSocket(connectionString);

      webSocket.onopen = (event) => {
        webSocket.send(JSON.stringify(authorizationBody));
      };

      webSocket.onmessage = (event) => {
        receivedData = JSON.parse(event.data);
        const subscriptionStockPayload = {
          t: "t",
          k: subscriberUID ??  "NSE|3045"
      }
        if (receivedData.t === "ck") {
          webSocket.send(JSON.stringify(subscriptionStockPayload));
          return;
        }

        const currentDate = new Date();

        let candleStartTime = 0;
        const isWeekOrMonthSelected = ['1W', '1M'].includes(resolution);
        if (isWeekOrMonthSelected) {
          const adjustedDate = adjustToStartOfWeekAndMonth((receivedData?.ft * 1000) || (currentDate.getTime() * 1000));
          candleStartTime = resolution === '1W' ? adjustedDate.startOfWeekEpoch : adjustedDate.startOfMonthEpoch;
        } else {
          candleStartTime = Math.floor(currentDate.getTime() / (secondsMap[resolution] * 1000));
          candleStartTime *= secondsMap[resolution] * 1000;
        }
        let epochTimeInMinutes = candleStartTime;

        console.log(lastDailyBar, receivedData, initBar, prevEpoch, epochTimeInMinutes, "from mee")


        if (epochTimeInMinutes > prevEpoch) {
          if (receivedData?.lp) {
            if (resolution === "1D" || resolution === "1W" || resolution === "1M") {
                initBar = {
                  time: receivedData.ft,
                  open: receivedData.o || initBar.open || lastDailyBar.open,
                  high: Math.max(receivedData.h || Number.NEGATIVE_INFINITY, initBar.high),
                  low: Math.min(receivedData.l || Number.POSITIVE_INFINITY, initBar.low),
                  close: receivedData.lp,
                  // volume: receivedData.v || lastDailyBar.volume
                }
            } else {
              // lastDailyBar.volume = Number(receivedData.v) || lastDailyBar.volume;
              // const vol = (Number(receivedData.v) - Number(lastDailyBar.volume));
              initBar = {
                time: receivedData.ft,
                open: receivedData.lp,
                high: receivedData.lp,
                low: receivedData.lp,
                close: receivedData.lp,
                // volume: vol || initBar.volume
              }
            }
            prevEpoch = epochTimeInMinutes
          }

        } else {
          //Older candle is getting updated
          if (!receivedData?.lp) {
            return;
          }
          if (resolution === "1D" || resolution === "1W" || resolution === "1M") {

              if (epochTimeInMinutes > lastDailyBar?.time) {
                initBar['time'] = receivedData.ft
                initBar['open'] = receivedData.o || initBar.open;
                initBar['high'] = Math.max(receivedData.h || Number.NEGATIVE_INFINITY, initBar.high);
                initBar['low'] = Math.min(receivedData.l || Number.POSITIVE_INFINITY, initBar.low);
                initBar['close'] = receivedData.lp;
                // initBar['volume'] = receivedData?.v ? Number(receivedData.v) : initBar.volume;
              } else {
                initBar['time'] = receivedData.ft
                initBar['open'] = lastDailyBar.open || receivedData.o || initBar.open;
                initBar['high'] = Math.max((receivedData.h || Number.NEGATIVE_INFINITY), initBar.high, lastDailyBar.high);
                initBar['low'] = Math.min((receivedData.l || Number.POSITIVE_INFINITY), initBar.low, lastDailyBar.low);
                initBar['close'] = receivedData.lp;
                // initBar['volume'] = receivedData?.v ? Number(receivedData.v) + Number(lastDailyBar?.volume) : initBar.volume;
              }
          } else {
            console.log(lastDailyBar, initBar, receivedData, "from else this means bar getting update")
            // const vol = (Number(receivedData.v) - Number(lastDailyBar.volume))
              if (receivedData?.lp) {
                if (initBar.open === Number.NEGATIVE_INFINITY) {
                  initBar.open = receivedData.lp
                  console.log("inside infinite negative");
                }
                initBar.time = epochTimeInMinutes
                initBar.high = Math.max(receivedData.lp || Number.NEGATIVE_INFINITY, initBar.high)
                initBar.low = Math.min(receivedData.lp || Number.POSITIVE_INFINITY, initBar.low)
                initBar.close = receivedData.lp
                // initBar.volume = vol || initBar.volume
              }
            }
        }

        console.log(receivedData, epochTimeInMinutes, initBar, "after everything")

        if(receivedData?.lp) {
          initBar.time = receivedData?.ft * 1000
          initBar.close = receivedData.lp
        }

        if(initBar?.open !== Number.NEGATIVE_INFINITY) {
          const channelString = subscriberUID;
          const subscriptionItem = channelToSubscription.get(channelString);
          subscriptionItem.lastDailyBar = initBar;
          subscriptionItem.handlers.forEach(handler => handler.callback(initBar));
        }
      };
    }
  } catch (error) {
    console.error(error);
  }
}

export function subscribeOnStream(
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscriberUID,
    onResetCacheNeededCallback,
    lastDailyBar
)
{

  console.log("calling subscribe to stream after resolution change", channelToSubscription)
  getData(symbolInfo, subscriberUID, resolution, lastDailyBar)
  // console.log(subscriberUID, "from subscribe on stream function")

    const parsedSymbol = symbolInfo.name;
    const channelString = subscriberUID;
    const handler = {
        id: subscriberUID,
        callback: onRealtimeCallback
    };

  let subscriptionItem = channelToSubscription.get(channelString);

    if (subscriptionItem) {
        subscriptionItem.handlers.push(handler);
        return;
    }
    subscriptionItem = {
        subscriberUID,
        resolution,
        lastDailyBar,
        handlers: [handler],
    };
    channelToSubscription.set(channelString, subscriptionItem);
};

export function unsubscribeFromStream(subscriberUID) {
  for (const channelString of channelToSubscription.keys()) {
    const subscriptionItem = channelToSubscription.get(channelString);
    const handlerIndex = subscriptionItem.handlers
      .findIndex(handler => handler.id === channelString);


    if (handlerIndex !== -1) {
        // Remove from handlers
      subscriptionItem.handlers.splice(handlerIndex, 1);

      if (subscriptionItem.handlers.length === 0) {
          console.log(subscriptionItem.handlers, channelString, channelToSubscription, "from unsub method")
            // TODO: Write unsubscribe logic here for kambala socket send channel string to unsubscribe
            channelToSubscription.delete(channelString);
            break;
        }
    }
}
}